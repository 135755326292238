import image1 from '../assets/images/ns_tunnel.png';
import image2 from '../assets/images/apex_tunnel.png';
import image3 from '../assets/images/ns_plus_vip.png';
import image4 from '../assets/images/rafi_fast_net.png';
import image5 from '../assets/images/mgtunnel.png';




const productData = [
    {
      id: 1,
      name: "NS TUNNEL",
      image: image1,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://nstunnelvpn.xyz",
      appLink: "https://tinyurl.com/NsTunnel-Apk"
    },
    
    {
      id: 2,
      name: "APEX TUNNEL",
      image: image2,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://nstunnelvpn.xyz",
      appLink: "https://tinyurl.com/ApexTunnel-Apk"
    },
    {
      id: 3,
      name: "NS PLUS VIP",
      image: image3,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://nstunnelvpn.xyz",
      appLink: "https://tinyurl.com/NsPlusVip-Apk"
    },
    {
      id: 4,
      name: "Sumaiya Lite",
      image: image4,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://nstunnelvpn.xyz",
      appLink: "https://tinyurl.com/sumaiyalite-apk"
    },  {
      id: 5,
      name: "MG TUNNEL",
      image: image5,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://nstunnelvpn.xyz",
      appLink: "https://tinyurl.com/MgTunnel-Apk"
    }
  ];
  export default productData;
